import moment from 'moment'
import consts from '../consts'

export default {
    getSlotCancellationLimit(slot, cancelDelay) {
        return moment(slot.StartDate).subtract(cancelDelay, 'hours').format()
    },

    getAgeLimits(activity, slotRepeat) {
        let minAge = activity.MinAge
        let maxAge = activity.MaxAge
        let typeMinAge = activity.TypeMinAge
        let typeMaxAge = activity.TypeMaxAge
        let minAgeTolerance = activity.MinAgeToleranceMonths
        let maxAgeTolerance = activity.MaxAgeToleranceMonths

        // User slot repeat limits if defined
        if (slotRepeat && (slotRepeat.MinAge || slotRepeat.MaxAge)) {
            minAge = slotRepeat.MinAge
            typeMinAge = slotRepeat.AgeMinType
            minAgeTolerance = slotRepeat.MinAgeDelta
            maxAge = slotRepeat.MaxAge
            typeMaxAge = slotRepeat.AgeMaxType
            maxAgeTolerance = slotRepeat.MaxAgeDelta
        }

        return {
            MinAge: minAge, MaxAge: maxAge,
            TypeMinAge: typeMinAge, TypeMaxAge: typeMaxAge,
            MinAgeTolerance: minAgeTolerance, MaxAgeTolerance: maxAgeTolerance
        }
    },

    clientMatchAge(client, activity, slotRepeat, startDate) {
        if (client) {
            // Redefine start date if in past or according to slot repeat option
            // If it's an already started periodic session we want to check against now instead of session start
            // It would be better to check against the next coming slot of the session, but itwould be a little overkill
            if (moment(startDate).isBefore(moment()) || (slotRepeat && slotRepeat.DeltaMode === consts.DELTA_MODE.CURRENT_YEAR)) {
                startDate = moment().format()
            }

            const clientAgeInMonth = moment(startDate).diff(moment(client.BirthDate), 'months')

            const limits = this.getAgeLimits(activity, slotRepeat)

            let minAgeMonth = limits.MinAge
            if (limits.TypeMinAge === 1) {
                minAgeMonth *= 12
            }

            let maxAgeMonth = limits.MaxAge
            if (limits.TypeMaxAge === 1) {
                maxAgeMonth *= 12
            }

            if (limits.MinAge !== 0 && clientAgeInMonth < minAgeMonth - limits.MinAgeTolerance) {
                return false
            }
            if (limits.MaxAge !== 0 && clientAgeInMonth > maxAgeMonth + limits.MaxAgeTolerance) {
                return false
            }
        }

        return true
    },

    clientMatchActivityMedicalCert(client, slot, certValidity = 3) {
        let validMedicert = true
        if (client) {
            let compareDate = moment()
            if (slot) {
                compareDate = moment(slot.StartDate)
            }

            if (slot.Activity && slot.Activity.IsMedicertRequired) {
                if (!!client.MedicertIssueDate) {       
                    validMedicert = moment(client.MedicertIssueDate).add(certValidity, 'years').isAfter(compareDate)
                } else if (!!client.MedicertLimitDate) {
                    validMedicert = moment(client.MedicertLimitDate).isAfter(compareDate)
                }
            }
        }
        return validMedicert
    },

    getSlotAgeIntervalText(activity, slotRepeat) {
        const limits = this.getAgeLimits(activity, slotRepeat)
        let text = ''

        if (limits.MinAge === 0 && limits.MaxAge !== 0) {
            text = 'moins de ' + limits.MaxAge + ' ' + this.getSlotAgeUnit(limits.TypeMaxAge, limits.MaxAge)
            if (limits.MaxAgeTolerance) {
                text += ' (+/- ' + limits.MaxAgeTolerance + ' mois)'
            }
        } else if (limits.MinAge !== 0 && limits.MaxAge === 0) {
            text = 'plus de ' + limits.MinAge + ' ' + this.getSlotAgeUnit(limits.TypeMinAge, limits.MinAge)
            if (limits.MinAgeTolerance) {
                text += ' (+/- ' + limits.MinAgeTolerance + ' mois)'
            }
        } else if (limits.MinAge !== 0 && limits.MaxAge !== 0) {
            text = 'entre ' + limits.MinAge + ' ' + this.getSlotAgeUnit(limits.TypeMinAge, limits.MinAge)
            if (limits.MinAgeTolerance) {
                text += ' (+/- ' + limits.MinAgeTolerance + ' mois)'
            }
            text += ' et ' + limits.MaxAge + ' ' + this.getSlotAgeUnit(limits.TypeMaxAge, limits.MaxAge)
            if (limits.MaxAgeTolerance) {
                text += ' (+/- ' + limits.MaxAgeTolerance + ' mois)'
            }
        }

        return text
    },

    getSlotAgeUnit(type, value) {
        if (type === 1) {
            return value > 1 ? 'ans' : 'an'
        } else {
            return 'mois'
        }
    }
}
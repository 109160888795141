import bookingsHelper from '../helpers/bookings'
import ui, { bookingStatuses, bookingRepeatStatuses, bookingSlotWebModes, bookingSlotRepeatPublicationStatuses, bookingCheckCodes} from '../ui/bookings'


export default {
    data: () => ({
        bookingStatuses,
        bookingRepeatStatuses,
        bookingSlotWebModes,
        bookingSlotRepeatPublicationStatuses,
        bookingCheckCodes
    }),
    methods: {
        ...bookingsHelper,
        ...ui,
    }
}
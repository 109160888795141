import './common'
import axios from 'axios'

export default {
    // Quotations
    getQuotation: (quotationuuid, params) => axios.get(`/quotations/${quotationuuid}`, { params }),
    getPaginatedQuotations: (params) => axios.get('/quotations-paginated', { params }),
    getQuotations: (params) => axios.get('/quotations', { params }),
    postQuotation: (data) => axios.post('/quotations', data),
    putQuotation: (quotationuuid, data) => axios.put(`/quotations/${quotationuuid}`, data),
    cancelQuotation: (quotationuuid) => axios.post(`/quotations/${quotationuuid}/cancel`),

    getQuotationLine: (quotationuuid, lineuuid, params) => axios.get(`/quotations/${quotationuuid}/lines/${lineuuid}`, { params }),
    getQuotationLines: (quotationuuid, params) => axios.get(`/quotations/${quotationuuid}`, { params }),
    postQuotationLine: (quotationuuid, data) => axios.post(`/quotations/${quotationuuid}`, data),
    putQuotationLine: (quotationuuid, lineuuid, data) => axios.put(`/quotations/${quotationuuid}/lines/${lineuuid}`, data),
    deleteQuotationLine: (quotationuuid, lineuuid) => axios.delete(`/quotations/${quotationuuid}/lines/${lineuuid}`),

    getQuotationhHistory: (quotationuuid, version, params) => axios.get(`/quotations/${quotationuuid}/history/${version}`, { params }),
    getQuotationhHistories: (quotationuuid, params) => axios.get(`/quotations/${quotationuuid}/history`, { params }),

    getQuotationSale: (quotationuuid) => axios.get(`/quotations/${quotationuuid}/sales`),
    getQuotationSales: (params) => axios.get('/quotes/sales', { params }),
    postQuotationSale: (quotationuuid, data) => axios.post(`/quotations/${quotationuuid}/sales`, data),
    putQuotationSale: (quotationuuid, saleuuid, data) => axios.put(`/quotations/${quotationuuid}/sales/${saleuuid}`, data),
    getQuotationSaleBill: (orderuuid) => axios.get(`/quotes/bill/${orderuuid}/`, { responseType: 'blob' }),

    getQuotationExportPDF: (quotationuuid, params) => axios.get(`/quotations/${quotationuuid}/export/pdf`, { responseType: 'blob', params }),
    getQuotationExportExcel: (quotationuuid, params) => axios.get(`/quotations/${quotationuuid}/export/excel`, { responseType: 'blob', params }),
    getQuotationsExportExcel: (params) => axios.get('/quotes/export/excel', { responseType: 'blob', params }),

    postQuotationSendMail: (quotationuuid, data) => axios.post(`/quotations/${quotationuuid}/send/mail`, data),

    // Pricings
    getQuotationPricing: (pricinguuid, params) => axios.get(`/quotes/pricings/${pricinguuid}`, { params }),
    getQuotationPricings: (params) => axios.get('/quotes/pricings', { params }),
    postQuotationPricing: (data) => axios.post('/quotes/pricings', data),
    putQuotationPricing: (pricinguuid, data) => axios.put(`/quotes/pricings/${pricinguuid}`, data),
    deleteQuotationPricing: (pricinguuid) => axios.delete(`/quotes/pricings/${pricinguuid}`),
    // Products
    getQuotationProduct: (productuuid, params) => axios.get(`/quotes/products/${productuuid}`, { params }),
    getQuotationProducts: (params) => axios.get('/quotes/products', { params }),
    postQuotationProduct: (data) => axios.post('/quotes/products', data),
    putQuotationProduct: (productuuid, data) => axios.put(`/quotes/products/${productuuid}`, data),
    deleteQuotationProduct: (productuuid) => axios.delete(`/quotes/products/${productuuid}`),
    initQuotationProducts: () => axios.get('/quotes/products-init'),
    // Families
    getQuotationProductsFamilie: (familyuuid, params) => axios.get(`/quotes/families/${familyuuid}`, { params }),
    getQuotationProductsFamilies: (params) => axios.get('/quotes/families', { params }),
    postQuotationProductsFamily: (data) => axios.post('/quotes/families', data),
    putQuotationProductsFamily: (familyuuid, data) => axios.put(`/quotes/families/${familyuuid}`, data),
    deleteQuotationProductsFamily: (familyuuid) => axios.delete(`/quotes/families/${familyuuid}`),
    // Catalogs
    getQuotationProductsCatalogue: (catalogueuuid, params) => axios.get(`/quotes/catalogues/${catalogueuuid}`, { params }),
    getQuotationProductsCatalogues: (params) => axios.get('/quotes/catalogues', { params }),
    postQuotationProductsCatalogue: (data) => axios.post('/quotes/catalogues', data),
    putQuotationCatalogue: (catalogueuuid, data) => axios.put(`/quotes/catalogues/${catalogueuuid}`, data),
    deleteQuotationCatalogue: (catalogueuuid) => axios.delete(`/quotes/catalogues/${catalogueuuid}`),

    // Clients
    getQuotationClient: (clientuuid, params) => axios.get(`/quotes/clients/${clientuuid}`, { params }),
    getQuotationClients: (params) => axios.get('/quotes/clients', { params }),
    postQuotationClient: (data) => axios.post('/quotes/clients', data),
    putQuotationClient: (clientuuid, data) => axios.put(`/quotes/clients/${clientuuid}`, data),
    importClients: (data) => axios.post('/quotes/import-clients', data),
    deleteQuotationClient: (clientuuid) => axios.delete(`/quotes/clients/${clientuuid}`),
    getQuotationClientProductsAndFamilies: (clientuuid, params) => axios.get(`/quotes/clients/${clientuuid}/products-and-families`, { params }),

    // Sales Representatives
    getQuotationSalesRepresentative: (clientuuid, params) => axios.get(`/quotes/sales-representatives/${clientuuid}`, { params }),
    getQuotationSalesRepresentatives: (params) => axios.get('/quotes/sales-representatives', { params }),
    postQuotationSalesRepresentative: (data) => axios.post('/quotes/sales-representatives', data),
    putQuotationSalesRepresentative: (clientuuid, data) => axios.put(`/quotes/sales-representatives/${clientuuid}`, data),
    deleteQuotationSalesRepresentative: (clientuuid) => axios.delete(`/quotes/sales-representatives/${clientuuid}`)
}

import './common'
import axios from 'axios'

export default {
    getTurnstiles: () => axios.get('/ctm/turnstiles'),
    getPassings: (params) => axios.get('/ctm/passings', { params }),
    sendTurnstilePassing: (data) => axios.post('/ctm/passings', data),
    getPassingsExcel: (params) => axios.get('/ctm/passings', { params, responseType: 'blob' }),
    postGroups: (group) => axios.post('/ctm/groups', group),
    putGroups: (group, params = {}) => axios.put(`/ctm/groups/${group.ID}`, { Params: params, Data: group }), // Params : { updateConfig: false } (updateConfig for send config signal to turnstile electronic card)
    deleteGroups: (group) => axios.delete(`/ctm/groups/${group.ID}`, group),
    sendAccessControlGroupSignal: (data) => axios.post('/ctm/groups/send-signal', data),
    getHardwareDiscovery: () => axios.get('/ctm/hardware/discovery'),
    getHardwareNodeInfo: (address) => axios.get(`/ctm/hardware/node-info/${address}`),
    getHardwareConfigPuppet: (address) => axios.get(`/ctm/hardware/config/puppet/${address}`),
    postHardwareConfigPuppet: (address, config) => axios.post(`/ctm/hardware/config/puppet/${address}`, config),
    getHardwareConfigStreambox: (address) => axios.get(`/ctm/hardware/config/streambox/${address}`),
    getHardwareConfigPreRunSH: (address) => axios.get(`/ctm/hardware/config/pre-run-sh/${address}`),
    getHardwarePuppetFacts: () => axios.get('/ctm/hardware/puppet-facts'),
    postHardwarePuppetApply: (address) => axios.post(`/ctm/hardware/puppet-apply/${address}`),
    postHardwareNetworkIpInterface: (address, intf, config, udp) => axios.post(`/ctm/hardware/network/${address}/${encodeURI(intf)}?udp=${udp}`, config),
    postHardwareConfigStreambox: (address, config) => axios.post(`/ctm/hardware/config/streambox/${address}`, config),
    postHardwareConfigPreRunSH: (address, config) => axios.post(`/ctm/hardware/config/pre-run-sh/${address}`, config),
    postHardwareRestartStreambox: (address) => axios.post(`/ctm/hardware/restart-streambox/${address}`),
    postHardwareReboot: (address) => axios.post(`/ctm/hardware/reboot/${address}`),
    postHardwareHostname: (address, hostname) => axios.post(`/ctm/hardware/hostname/${address}`, hostname, { headers: { 'Content-Type': 'application/json' } })
}
